<template>
<!--  :title="`Mac：${deviceMac}`" -->
	<b-card class="py-1" v-loading="loading">
		<b-row>
			<b-col cols="12"><h3 class="mb-2">{{$t('Base Info')}}</h3></b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>{{$t('Width')}}</h6>
				<p>{{infoData.width}}px</p>
			</b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>{{$t('Height')}}</h6>
				<p>{{infoData.height}}px</p>
			</b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>MAC</h6>
				<p>{{deviceMac}}</p>
			</b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>IP</h6>
				<p>123.6.49.38</p>
			</b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>{{$t('Volumn')}}</h6>
				<p v-if="volumeSetStatus==0">
					{{infoData.volume}}
					<b-button class="ml-2" variant="outline-primary" size="sm" @click="setVolume">{{$t('Set')}}</b-button>
				</p>
				<p class="d-flex align-items-center" v-else>
					<Slider
						v-model="volume"
						style="width:60px"
						:min="1"
						:max="15"
						showTooltip="drag"
						:classes="{
							connect: 'slider-connect bg-primary',
							tooltip: 'slider-tooltip bg-primary border-color-primary',
							tooltipBottom: 'slider-tooltip-bottom border-color-primary'
						}"
					/>
					<b-button class="ml-2" variant="primary" size="sm" :disbled="saving" @click="saveVolumn">{{$t('Save')}}</b-button>
					<b-button class="ml-1" variant="outline-primary" :disbled="saving" size="sm" @click="volumeSetStatus=0">{{$t('Cancel')}}</b-button>
				</p>
			</b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>{{$t('Brightness')}}</h6>
				<p v-if="lightSetStatus==0">
					{{infoData.light}}
					<b-button class="ml-2" variant="outline-primary" size="sm" @click="lightSetStatus=1">{{$t('Set')}}</b-button>
				</p>
				
				<p class="d-flex align-items-center" v-else>
					<Slider
						v-model="light"
						style="width:60px"
						:min="1"
						:max="255"
						showTooltip="drag"
						:classes="{
							connect: 'slider-connect bg-primary',
							tooltip: 'slider-tooltip bg-primary border-color-primary',
							tooltipBottom: 'slider-tooltip-bottom border-color-primary'
						}"
					/>
					<b-button class="ml-2" variant="primary" size="sm" :disbled="saving" @click="saveBright">{{$t('Save')}}</b-button>
					<b-button class="ml-1" variant="outline-primary" :disbled="saving" size="sm" @click="lightSetStatus=0">{{$t('Cancel')}}</b-button>
				</p>
			</b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>{{$t('Status')}}</h6>
				<p>
					{{infoData.screen==0? $t('Offline') : $t('Online')}} 
					<b-button class="ml-2" variant="outline-primary" size="sm" :disbled="saving" @click="controlScreen(1)" v-if="infoData.screen==0">{{$t('Open')}}</b-button>
					<b-button class="ml-2" variant="outline-primary" size="sm" :disbled="saving" @click="controlScreen(0)" v-else>{{$t('Close')}}</b-button>
				</p>
			</b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>{{$t('CPU Utilization')}}</h6>
				<p>{{infoData.cpu}}%</p>
			</b-col>
			<!-- RAM -->
			<b-col cols="12"><h3 class="mb-2 mt-1">RAM</h3></b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>{{$t('Total')}}</h6>
				<p>{{infoData.ram && bytesToSize(infoData.ram)}}</p>
			</b-col>
			<b-col class="pb-2" cols="6" md="4" lg="3">
				<h6>{{$t('Used')}}</h6>
				<p>{{infoData.ram_used && bytesToSize(infoData.ram_used)}}</p>
			</b-col>

			<!-- ROM -->
			<b-col cols="12"><h3 class="mb-2 mt-2">ROM</h3></b-col>
			<b-col cols="6" md="4" lg="3">
				<h6>{{$t('Total')}}</h6>
				<p>{{infoData.rom && bytesToSize(infoData.rom)}}</p>
			</b-col>
			<b-col cols="6" md="4" lg="3">
				<h6>{{$t('Used')}}</h6>
				<p>{{infoData.rom_used && bytesToSize(infoData.rom_used)}}</p>
			</b-col>
		</b-row>
		
	</b-card>
</template>

<script>
import { BCard, BCardText, BRow, BCol,BButton, BFormInput } from 'bootstrap-vue'
import Slider from '@vueform/slider/dist/slider.vue2.js'
import { getDeviceInfo,sendDeviceCommand } from "@/libs/apis/serverApi";
import { bytesToSize } from "@/libs/utils"

export default {
	components: {
		BCard,
		BCardText,
		BRow, BCol,
		BButton,
		BFormInput,
		Slider
	},
	data () {
		return {
			value:1,
			deviceId: this.$route.query.id,
			deviceMac: this.$route.query.mac,
			volumeSetStatus: 0,
			lightSetStatus: 0,
			light: 0,
			volume: 0,
			loading: false,
			infoData: {}
		}
	},
	created () {
		if(!this.deviceId) {
			this.$router.back()
			return
		}
		this.getData ()
	},
	methods: {
		bytesToSize,
		getData () {
			this.loading = true
			getDeviceInfo({deviceid: this.deviceId}).then(res=>{
				this.loading = false
				this.infoData = res
			},err=>{
				this.loading = false
				this.$toast.error(err.message)
			})
		},
		setVolume () {
			this.volumeSetStatus=1
			this.volume = this.infoData.volume
		},
		saveVolumn () {
			this.saving = true
			sendDeviceCommand({
				deviceid: this.deviceId,
				type: 102,
				obj: {
					value: this.volume
				}
			}).then(res=>{
				this.saving = false
				this.volumeSetStatus = 0
				this.infoData.volume = this.volume
			},err=>{
				this.saving = false
				this.$toast.error(err.message)
			})
			
		},
		saveBright () {
			this.saving = true
			sendDeviceCommand({
				deviceid: this.deviceId,
				type: 101,
				obj: {
					value: this.light
				}
			}).then(res=>{
				this.saving = false
				this.lightSetStatus = 0
				this.infoData.light = this.light
			},err=>{
				this.saving = false
				this.$toast.error(err.message)
			})
		},
		controlScreen (status) {
			this.saving = true
			sendDeviceCommand({
				deviceid: this.deviceId,
				type: 103,
				obj: {
					value: status
				}
			}).then(res=>{
				this.saving = false
				this.infoData.screen = status
			},err=>{
				this.saving = false
				this.$toast.error(err.message)
			})
		}
	}
}
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style>

</style>
